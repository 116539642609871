<template>
<div>
    <NavTools />
    <div style="background-color:#fff">
        <div class='container' style="max-width: 800px;">
            <div class="row">
                <div class='col-md-12'>
                    <h3>What is a “Virtual Star”?</h3>
                    <p class="lead">Students receive only two virtual stars per application cycle. The feature is not activated in the student’s InitialView account until approximately December 15th each cycle (this is to prevent a star from interfering with ED/EA data). If an institution receives a star, then it knows that it has received one of two stars that the student has. </p>
                    <p class="lead">Given that receipt of a star is an intentional signal of interest from the student to the institution, many institutions make sure to include the stars in their application review and often run a query once apps are in in order to find all of the applications that have provided them with stars.</p>
                    <p class="lead">There is no extra charge to the student for this feature.</p>

                    <div class="well">
                        <img :src="staticaddr + '/ivgcs/img/amelia-stars.png'" class="img-responsive">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import VueResource from 'vue-resource';
Vue.use(VueResource);

import NavTools from "@/components/NavTools.vue";

// require("@/utils/LoginRequired.js");

export default {

    props: [
        'staticaddr',
        'serveraddr',
        'ameliaaddr',
        'lang',
        'lang_label',
    ],

    components: {
        NavTools
    },
}
</script>

<style lang="scss">

</style>
