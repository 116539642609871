import Vue from "vue";

console.log("ivbus");
var ivbus;
//$(document).ready(function(){
console.log("ivbus document ready");
ivbus = new Vue({
  created: function() {
    console.log("ivbus created");
    // $("#global-spinner").hide();
    this.flags = {};
    this.semaphores = {};
    this.semaphoresQueue = {};
    this.emits = {};
    this.ons = {};

    this.children = [];

    // this.parent =
    //   window.opener && window.opener.ivbus ? window.opener.ivbus : this;
    this.parent = null;
    this.parentOrigin = ''
    this.parentPreConnectMessageQueue = [];

  },
  methods: {
    setFlag: function(key, value) {
      console.log("ivbus setFlag " + key);
      this.flags[key] = value;
      this.$emit("setFlag_" + key, {
        key: value
      });
    },
    getFlag: function(key) {
      console.log("ivbus getFlag " + key);
      var that = this;
      return new Promise(function(resolve, reject) {
        if (typeof that.flags[key] !== "undefined") {
          resolve(that.flags[key]);
        } else {
          that.$on("setFlag_" + key, function(obj) {
            resolve(that.flags[key]);
          });
        }
      });
    },
    getFlagSticky: function(key, cb) {
      var that = this;
      if (typeof that.flags[key] !== "undefined") {
        cb(that.flags[key]);
      }
      that.$on("setFlag_" + key, function(obj) {
        cb(that.flags[key]);
      });
    },
    takeSemaphore: function(key, joinQueue) {
      console.log("ivbus takeSemaphore " + key);
      var that = this;
      return new Promise(function(resolve, reject) {
        if (!that.semaphores[key]) {
          that.semaphores[key] = true;
          resolve();
        } else {
          if (joinQueue) {
            if (typeof that.semaphoresQueue[key] === "undefined") {
              that.semaphoresQueue[key] = [];
            }
            that.semaphoresQueue[key].push(resolve);
          }
        }
      });
    },
    releaseSemaphore: function(key) {
      var that = this;
      if (that.semaphoresQueue[key] && that.semaphoresQueue[key].length > 0) {
        try {
          that.semaphoresQueue[key].unshift()();
        } catch (e) {}
      } else {
        that.semaphores[key] = false;
      }
    },
    onQ: function(key, cb) {
      if (this.emits[key]) {
        this.emits[key].forEach(function(obj) {
          cb(obj);
        });
      }
      this.ons[key] = true;
      this.$on(key, cb);
    },
    emitQ: function(key, obj) {
      if (this.ons[key]) {
        this.$emit(key, obj);
      } else {
        (this.emits[key] || (this.emits[key] = [])).push(obj);
      }
    },
    // PostMessage Child Functions
    startPostMessageParentListener: function(parentOrigin) {
      // The child window should run this to listen for a parent.
      var that = this;
      window.addEventListener("message", (event) => {
        // console.debug(event.origin);
        // console.debug(event);
        // Do we trust the sender origin of this message?
        if (event.origin !== parentOrigin)
          return;
        // for single-origin communication you can check the window.opener == event.source
        
        // send back confirmation
        event.source.postMessage({ message: "PostMessage Link Successful", childID: event.data.childID, status: true}, event.origin);
        that.parent = event.source;
        that.parentOrigin = event.origin;
        for (let i = 0; i < that.parentPreConnectMessageQueue.length; i++) {
          const messageData = that.parentPreConnectMessageQueue[i];
          that.parent.postMessage(messageData, that.parentOrigin)
          // console.debug("queue message sent");
        }
      }, false);
    },
    sendPostMessageParentEmit: function(key, value) {
      if (this.parent != null) {
        this.parent.postMessage({emit:{ key:key, value:value}}, this.parentOrigin)
      } else {
        this.parentPreConnectMessageQueue.push({emit:{ key:key, value:value}});
      }
      // This is caught by the parent's postmessage listener and key/value is emitted.
    },
    // PostMessage Parent Functions
    startPostMessageChildInitiateLink: function(childWindow, childOrigin, childID) {
      // The parent window should run this to initiate a link with the child.
      window.addEventListener("message", (event) => {
        // console.debug(event.origin);
        // console.debug(event);
        // Do we trust the sender origin of this message?
        if (event.origin !== childOrigin)
          return;
        // for single-origin communication you can check the window.opener == event.source
        
        // accept confirmation
        if (event.data.status == true && event.data.message == "PostMessage Link Successful") {
          this.children[event.data.childID] = event.source;  // push the responding window to the child list.
        }
        if (event.data.emit != undefined) {
          this.$emit(event.data.emit.key, event.data.emit.value)
        }
      }, false);
      
      this.trySendingPostMessageChildInitiateLink(childWindow, childOrigin, childID, 40);

    },
    trySendingPostMessageChildInitiateLink: function(childWindow, childOrigin, childID, retries) {
      var that = this;
      if ( this.children[childID] == undefined) {
        //console.debug("retry: " + retries);
        window.setTimeout(function () {
          childWindow.postMessage({message:"Request Link", childID: childID}, childOrigin);
          // console.log("Request Link with Child Window");
          that.trySendingPostMessageChildInitiateLink(childWindow, childOrigin, childID, retries-1);
          //console.debug(that.children);
        }, 1000);
      } else {
        console.debug("response recieved. link successful.")
      }
    }

  }
});

//});  //close document ready

export default ivbus;
